const nav = {
    title: "TechNova",
    about: "About",
    contact: "Contact Us",
    faq: "FAQ",
    sponsor: "Sponsors",
    story: "Our Story",
    keynote: "Keynote",
    judges: "Judges"
};

export default nav;